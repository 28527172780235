import { Log } from '@x/common/log';

export type IEnvironment = Record<string, string | boolean | number>;

let ENVIRONMENT: IEnvironment = {};
let init = false;

export class Environment {
  static initServer(process_env: any) {
    console.log('Server init');

    // if (init) return;

    this.assertNotInit();
    ENVIRONMENT = this.getSafeKeys(process_env);
    init = true;
    Log.debug('Server environment initialized', ENVIRONMENT);
    return ENVIRONMENT;
  }

  static async initBrowser(url?: string) {
    console.log('Browser init');
    this.assertNotInit();

    // https://github.com/angular/angular/blob/7d3df4724d2baf087453d317ab6ab893d4a7b85f/packages/platform-browser/src/browser/transfer_state.ts#L96
    // Locate the script tag with the JSON data transferred from the server.
    // The id of the script tag is set to the Angular appId + 'state'.
    const script = window?.document?.getElementById('app-environment');

    if (script && script.textContent) {
      // Avoid using any here as it triggers lint errors in google3 (any is not allowed).
      ENVIRONMENT = JSON.parse(script.textContent) as {};
      Log.debug('Browser environment initialized from injection', ENVIRONMENT);
    } else if ((<any>window)?.__ENVIRONMENT) {
      ENVIRONMENT = Object.freeze((<any>window)?.__ENVIRONMENT);
      Log.debug('Browser environment initialized from window.__ENVIRONMENT', ENVIRONMENT);
    } else {
      const fetchUrl = url ?? 'environment/environment.json';
      ENVIRONMENT = Object.freeze(await (await fetch(fetchUrl)).json());
      Log.debug(`Browser environment initialized from ${fetchUrl}`, ENVIRONMENT);
    }
    init = true;

    return ENVIRONMENT;
  }

  static str(key: string, defaultValue: string): string {
    return this.envHasKey(key) ? String(ENVIRONMENT[key]) : defaultValue;
  }

  static requireStr(key: string): string {
    return this.envRequire(key) ?? String(ENVIRONMENT[key]);
  }

  static optStr(key: string): string | undefined {
    return this.envHasKey(key) ? String(ENVIRONMENT[key]) : undefined;
  }

  static bool<D = any>(key: string, defaultValue: D): boolean | D {
    return this.envHasKey(key) ? ENVIRONMENT[key] === 'true' : defaultValue;
  }

  static requireBool(key: string) {
    return this.envRequire(key) ?? ENVIRONMENT[key] === 'true';
  }

  static int<D = any>(key: string, defaultValue: D): number | D {
    return this.envHasKey(key) ? parseInt(<string>ENVIRONMENT[key], 10) : defaultValue;
  }

  static requireInt(key: string): number {
    return this.envRequire(key) ?? parseInt(<string>ENVIRONMENT[key], 10);
  }

  static float<D = any>(key: string, defaultValue: number): number | D {
    return this.envHasKey(key) ? parseFloat(<string>ENVIRONMENT[key]) : defaultValue;
  }

  static requireFloat(key: string): number {
    return this.envRequire(key) ?? parseFloat(<string>ENVIRONMENT[key]);
  }

  static csv<D = any>(key: string, defaultValue: D): Array<string> | D {
    return this.envHasKey(key) ? (<string>ENVIRONMENT[key]).split(',') : defaultValue;
  }

  static requireCsv(key: string) {
    return this.envRequire(key) ?? (<string>ENVIRONMENT[key]).split(',');
  }

  static toJson() {
    return JSON.stringify(ENVIRONMENT);
  }

  static getSafeKeys(source: Record<string, string | boolean | number | undefined>) {
    return Object.keys(source)
      .filter((k) => k.startsWith('NG_'))
      .reduce((env, k) => {
        if (
          typeof source[k] === 'string' ||
          typeof source[k] === 'number' ||
          typeof source[k] === 'boolean'
        ) {
          (<any>env[k]) = source[k];
        }

        return env;
      }, {} as IEnvironment);
  }

  static all() {
    return ENVIRONMENT;
  }

  private static assertInit(key: string) {
    if (!init) {
      throw new Error(`Attempting to load environment variable '${key}' before initialization.`);
    }
  }

  private static assertNotInit() {
    if (init) {
      throw new Error(`Environment already intialized.`);
    }
  }

  private static envHasKey(key: string) {
    this.assertInit(key);
    return ENVIRONMENT[key] !== undefined && ENVIRONMENT[key] !== '' && ENVIRONMENT[key] !== 'null';
  }

  private static envRequire(key: string) {
    if (!this.envHasKey(key)) {
      throw new Error(`Environment variable '${key}' is required.`);
    }
    return undefined;
  }

  requireBool(key: string) {
    return Environment.requireBool(key);
  }

  requireStr(key: string) {
    return Environment.requireStr(key);
  }

  optStr(key: string) {
    return Environment.optStr(key);
  }

  bool<D = any>(key: string, defaultValue: D) {
    return Environment.bool<D>(key, defaultValue);
  }
}
